<template>
	<div id="exam_detail" v-loading="loading" :style="{ height: height + 'px' }">
		<div class="head u-f-item u-f-jsb">
			<div>{{ name }}的约谈记录</div>
		</div>
		<div class="content">
			<div class="title u-f-item u-f-jsb">
				<div>共{{ list.length }}条数据</div>
				<el-button type="primary" size="mini" @click="exportsHeart">导出</el-button>
			</div>
			<el-table :data="list" height="70%" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;" key="2">
				<el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
				<el-table-column label="学生姓名" prop="user_name" align="center"></el-table-column>
				<el-table-column label="导师评价" prop="evaluate" align="center">
					<template slot-scope="scope">
						<el-tooltip class="item" style="width: 800px;" effect="dark" :content="scope.row.evaluate" placement="top-start">
							<span class="u-line-1" style="width: 90%;">{{ scope.row.evaluate }}</span>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column label="图片" align="center">
					<template slot-scope="scope">
						<div class="u-f-justify">
							<div v-for="(item,i) in scope.row.image" :key="i" style="margin-right: 5px;">
								<el-image
									fit="cover"
								    style="width: 50px; height: 50px;"
								    :src="'http://hlgzx.zhiyunapi.com'+item" 
								    :preview-src-list="['http://hlgzx.zhiyunapi.com'+item]">
								</el-image>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="状态" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.status==1">未抽查</span>
						<span v-if="scope.row.status==2">正常</span>
						<span v-if="scope.row.status==3">作废</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="150" align="center">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="caozuo(scope.row)">抽查</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="u-f" style="padding: 15px 0;" v-if="info.cnt">
				<div class="u-f1" style="margin-right: 15px;">
					<div style="font-weight: bold;">辅导内容</div>
					<div style="border: 1px solid #EEEEEE;width: 93%;height: 14vh;margin-top: 15px;padding: 15px;">
						{{info.cnt}}
					</div>
				</div>
				<div class="u-f1" style="margin-right: 15px;">
					<div style="font-weight: bold;">辅导效果</div>
					<div style="border: 1px solid #EEEEEE;width: 93%;height: 14vh;margin-top: 15px;padding: 15px;">
						<div v-if="info.type1">学生对遵规守纪的重要性有更深刻的认识</div>
						<div v-if="info.type2">学生对自己的错误有更深刻的认识</div>
						<div v-if="info.type3">学生了解了实用的方法或树立了合理的学习目标</div>
						<div v-if="info.type4">学生的负面情绪有所缓解</div>
						<div v-if="info.type5">学生了解到新的人际交往技巧</div>
					</div>
				</div>
				<div class="u-f1">
					<div style="font-weight: bold;">辅导类型</div>
					<div style="border: 1px solid #EEEEEE;width: 93%;height: 14vh;margin-top: 15px;padding: 15px;">
						{{info.type6?'集体辅导':'单人辅导'}}
					</div>
				</div>
			</div>
			<el-dialog title="操作" :visible.sync="isShow" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-justify input-box">
						<el-radio-group v-model="status">
						    <el-radio :label="1">未抽查</el-radio>
						    <el-radio :label="2">正常</el-radio>
						    <el-radio :label="3">作废</el-radio>
						  </el-radio-group>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="isShow=false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitadd">确 定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			height: 500,
			loading: false,
			id: '',
			list: [],
			name: '',
			isShow:false,
			status:1,
			list_id:'',
			info:''
		};
	},
	created() {},
	mounted() {
		this.height = document.documentElement.clientHeight || document.body.clientHeight;
		this.id = this.$route.query.id;
		this.name = this.$route.query.username;
		this.getList();
	},
	methods: {
		exportsHeart(){
			let data = {
				id: this.id
			};
			this.$api.setting.exportsHeart(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('导出成功');
					location.href = res.data.data;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		getList() {
			let data = {
				id: this.id
			};
			this.$api.setting.tutorDetails(data).then(res => {
				if (res.data.code == 1) {
					this.info = res.data.data;
					let list = res.data.data.list;
					this.list = list;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		caozuo(e){
			this.status = e.status;
			this.list_id = e.list_id;
			this.isShow = true;
		},
		handleClose(done){
			done()
		},
		submitadd(){
			this.$api.setting.checkOpera({
				id:this.id,
				status:this.status,
				list_id:this.list_id
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('操作成功');
					this.isShow = false;
					this.getList();
				}else{
					this.$message.error(res.data.msg);
				}
			})
		}
	}
};
</script>

<style lang="scss" scope>
#exam_detail {
	.head {
		background-color: #4998ff;
		padding: 15px 20px;
		div {
			color: #fff;
			font-size: 16px;
		}
	}
	.content {
		background-color: #ffffff;
		margin-top: 10px;
		padding: 0 30px;
		height: calc(100% - 80px);
		overflow-y: auto;
		.title {
			padding: 0 0 10px 0;
			.btn {
				background-color: #f5f5f5;
				padding: 5px;
			}
			.btn:hover {
				color: #4998ff;
				background: #e4f1ff;
				cursor: pointer;
			}
		}
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.el-table thead {
			background: #fafafa !important;
		}
		.el-table th {
			padding: 15px 5px !important;
			background: #fafafa !important;
		}
		.el-table::before {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0;
		}
		.el-table .btn {
			padding: 5px;
			text-align: center;
		}
	}
	.el-dialog__body {
		padding: 20px 20px 50px;
		.con-box {
			border-top: 1px solid #eee;
			margin-top: 15px;
			max-height: 400px;
			overflow-y: auto;
			> div {
				margin-top: 10px;
				background-color: #f6f8fb;
				padding: 15px;
				> div:first-child {
					margin-right: 30%;
				}
			}
			> div.none {
				color: #d8dddf;
			}
			> div.can:hover {
				color: #4998ff;
				background: #e4f1ff;
				cursor: pointer;
			}
			> div.selected {
				background-color: #4998ff;
				color: #fff;
			}
		}
		.page {
			margin-top: 10px;
			text-align: right;
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
